// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-content-sync-js": () => import("./../../../src/pages/content-sync.js" /* webpackChunkName: "component---src-pages-content-sync-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-design-labs-index-js": () => import("./../../../src/pages/design-labs/index.js" /* webpackChunkName: "component---src-pages-design-labs-index-js" */),
  "component---src-pages-frameable-js": () => import("./../../../src/pages/frameable.js" /* webpackChunkName: "component---src-pages-frameable-js" */),
  "component---src-pages-release-notes-tsx": () => import("./../../../src/pages/release-notes.tsx" /* webpackChunkName: "component---src-pages-release-notes-tsx" */),
  "component---src-pages-salary-calculator-index-js": () => import("./../../../src/pages/salary-calculator/index.js" /* webpackChunkName: "component---src-pages-salary-calculator-index-js" */),
  "component---src-pages-site-create-js": () => import("./../../../src/pages/site-create.js" /* webpackChunkName: "component---src-pages-site-create-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-about-us-index-js": () => import("./../../../src/templates/about-us/index.js" /* webpackChunkName: "component---src-templates-about-us-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/blog/tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-blog-tags-js": () => import("./../../../src/templates/blog/tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */),
  "component---src-templates-careers-index-js": () => import("./../../../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-careers-job-posting-js": () => import("./../../../src/templates/careers/job-posting.js" /* webpackChunkName: "component---src-templates-careers-job-posting-js" */),
  "component---src-templates-contributors-author-js": () => import("./../../../src/templates/contributors/author.js" /* webpackChunkName: "component---src-templates-contributors-author-js" */),
  "component---src-templates-custom-page-get-started-template-js": () => import("./../../../src/templates/custom-page/get-started_template.js" /* webpackChunkName: "component---src-templates-custom-page-get-started-template-js" */),
  "component---src-templates-custom-page-home-template-js": () => import("./../../../src/templates/custom-page/home_template.js" /* webpackChunkName: "component---src-templates-custom-page-home-template-js" */),
  "component---src-templates-custom-page-silly-site-faq-template-js": () => import("./../../../src/templates/custom-page/silly_site_faq_template.js" /* webpackChunkName: "component---src-templates-custom-page-silly-site-faq-template-js" */),
  "component---src-templates-custom-page-silly-site-template-js": () => import("./../../../src/templates/custom-page/silly_site_template.js" /* webpackChunkName: "component---src-templates-custom-page-silly-site-template-js" */),
  "component---src-templates-docs-api-reference-js": () => import("./../../../src/templates/docs/api-reference.js" /* webpackChunkName: "component---src-templates-docs-api-reference-js" */),
  "component---src-templates-docs-doc-section-js": () => import("./../../../src/templates/docs/doc-section.js" /* webpackChunkName: "component---src-templates-docs-doc-section-js" */),
  "component---src-templates-docs-doc-type-js": () => import("./../../../src/templates/docs/doc-type.js" /* webpackChunkName: "component---src-templates-docs-doc-type-js" */),
  "component---src-templates-docs-index-js": () => import("./../../../src/templates/docs/index.js" /* webpackChunkName: "component---src-templates-docs-index-js" */),
  "component---src-templates-features-cms-js": () => import("./../../../src/templates/features/cms.js" /* webpackChunkName: "component---src-templates-features-cms-js" */),
  "component---src-templates-features-feature-comparison-template-js": () => import("./../../../src/templates/features/feature-comparison-template.js" /* webpackChunkName: "component---src-templates-features-feature-comparison-template-js" */),
  "component---src-templates-features-index-js": () => import("./../../../src/templates/features/index.js" /* webpackChunkName: "component---src-templates-features-index-js" */),
  "component---src-templates-features-jamstack-js": () => import("./../../../src/templates/features/jamstack.js" /* webpackChunkName: "component---src-templates-features-jamstack-js" */),
  "component---src-templates-gatsby-days-details-js": () => import("./../../../src/templates/gatsby-days/details.js" /* webpackChunkName: "component---src-templates-gatsby-days-details-js" */),
  "component---src-templates-gatsby-days-index-js": () => import("./../../../src/templates/gatsby-days/index.js" /* webpackChunkName: "component---src-templates-gatsby-days-index-js" */),
  "component---src-templates-guide-default-index-js": () => import("./../../../src/templates/guide-default/index.js" /* webpackChunkName: "component---src-templates-guide-default-index-js" */),
  "component---src-templates-guide-react-index-js": () => import("./../../../src/templates/guide-react/index.js" /* webpackChunkName: "component---src-templates-guide-react-index-js" */),
  "component---src-templates-guidelines-color-js": () => import("./../../../src/templates/guidelines/color.js" /* webpackChunkName: "component---src-templates-guidelines-color-js" */),
  "component---src-templates-guidelines-logo-js": () => import("./../../../src/templates/guidelines/logo.js" /* webpackChunkName: "component---src-templates-guidelines-logo-js" */),
  "component---src-templates-guides-list-index-js": () => import("./../../../src/templates/guides-list/index.js" /* webpackChunkName: "component---src-templates-guides-list-index-js" */),
  "component---src-templates-how-it-works-index-js": () => import("./../../../src/templates/how-it-works/index.js" /* webpackChunkName: "component---src-templates-how-it-works-index-js" */),
  "component---src-templates-landing-page-index-js": () => import("./../../../src/templates/landing-page/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-templates-languages-index-js": () => import("./../../../src/templates/languages/index.js" /* webpackChunkName: "component---src-templates-languages-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-plugins-index-new-js": () => import("./../../../src/templates/plugins/index-new.js" /* webpackChunkName: "component---src-templates-plugins-index-new-js" */),
  "component---src-templates-plugins-plugin-readme-js": () => import("./../../../src/templates/plugins/plugin-readme.js" /* webpackChunkName: "component---src-templates-plugins-plugin-readme-js" */),
  "component---src-templates-preview-index-js": () => import("./../../../src/templates/preview/index.js" /* webpackChunkName: "component---src-templates-preview-index-js" */),
  "component---src-templates-pricing-index-js": () => import("./../../../src/templates/pricing/index.js" /* webpackChunkName: "component---src-templates-pricing-index-js" */),
  "component---src-templates-privacy-policy-index-js": () => import("./../../../src/templates/privacy-policy/index.js" /* webpackChunkName: "component---src-templates-privacy-policy-index-js" */),
  "component---src-templates-self-service-landing-page-index-js": () => import("./../../../src/templates/self-service-landing-page/index.js" /* webpackChunkName: "component---src-templates-self-service-landing-page-index-js" */),
  "component---src-templates-showcase-details-js": () => import("./../../../src/templates/showcase/details.js" /* webpackChunkName: "component---src-templates-showcase-details-js" */),
  "component---src-templates-showcase-index-js": () => import("./../../../src/templates/showcase/index.js" /* webpackChunkName: "component---src-templates-showcase-index-js" */),
  "component---src-templates-starters-details-js": () => import("./../../../src/templates/starters/details.js" /* webpackChunkName: "component---src-templates-starters-details-js" */),
  "component---src-templates-starters-index-js": () => import("./../../../src/templates/starters/index.js" /* webpackChunkName: "component---src-templates-starters-index-js" */),
  "component---src-templates-use-cases-index-js": () => import("./../../../src/templates/use-cases/index.js" /* webpackChunkName: "component---src-templates-use-cases-index-js" */),
  "component---src-templates-use-cases-landing-page-js": () => import("./../../../src/templates/use-cases/landing-page.js" /* webpackChunkName: "component---src-templates-use-cases-landing-page-js" */),
  "component---src-templates-why-gatsby-index-js": () => import("./../../../src/templates/why-gatsby/index.js" /* webpackChunkName: "component---src-templates-why-gatsby-index-js" */)
}

